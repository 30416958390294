/* Author : Daniel Nikiel */
/* Url    : www.awdinterior.com */
/* E-mail : daniel.nikiel@gmail.com */

/*------------------------------------------------------------
[ Core Layout]
--------------------------------------------------------------*/
import '../scss/admin.scss';
import '../bootstrap';

const $ = require('jquery');
require('bootstrap');


$("#tableFilterInput").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#dataTable tbody tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });